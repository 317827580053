/** @format */

import { useCallback } from "react";
import { constants } from "ethers";
import { useUpdateAtom } from "jotai/utils";
import { useSnackbar } from "notistack";
import { loadingAllAtom } from "@atoms/loading";
import { availableAtomFamily } from "@atoms/balance";
import { Token } from "@utils/constants";
import {
  // usePHB,
  useJIT,
  useLP,
  useL2RBnbLP,
  useL2RBusdPL,
  // useDeprecatedLP,
  // useLegacyLP,
} from "./useContract";
import { useAccount } from "wagmi";
import useFetchStakingData from "./useFetchStakingData";

export default function useFetchState() {
  const { address } = useAccount();

  const { enqueueSnackbar } = useSnackbar();

  // token available
  // const phbToken = usePHB();
  const JITToken = useJIT();
  const lpToken = useLP();
  const lpContractL2RBnb = useL2RBnbLP();
  const lpContractL2RBusd = useL2RBusdPL();
  // const deprecatedLpToken = useDeprecatedLP();
  // const legacyLpToken = useLegacyLP();

  // all loading
  const setLoading = useUpdateAtom(loadingAllAtom);

  // available atoms
  // const setAvailablePHB = useUpdateAtom(availableAtomFamily(Token.PHB));
  const setAvailableJIT = useUpdateAtom(availableAtomFamily(Token.JIT));
  const setAvailableLP = useUpdateAtom(availableAtomFamily(Token.JIT_BNB_LP));
  const setAvailableLP1 = useUpdateAtom(availableAtomFamily(Token.JIT_L2R_LP));
  const setAvailableLP2 = useUpdateAtom(
    availableAtomFamily(Token.JIT_L2R_BUSD_LP),
  );

  // const setAvailableDeprecatedLP = useUpdateAtom(
  //   availableAtomFamily(Token.JIT_BNB_LP_DEPRECATED)
  // );
  // const setAvailableLegacyLP = useUpdateAtom(
  //   availableAtomFamily(Token.JIT_BNB_LP_LEGACY)
  // );

  // fetch token staking data
  // const fetchPHBStakingData = useFetchStakingData(Token.PHB);
  const fetchJITStakingData = useFetchStakingData(Token.JIT);
  const fetchLPStakingData = useFetchStakingData(Token.JIT_BNB_LP);
  const fetchLPStakingData1 = useFetchStakingData(Token.JIT_L2R_LP);
  const fetchLPStakingData2 = useFetchStakingData(Token.JIT_L2R_BUSD_LP);

  // const fetchDeprecatedLPStakingData = useFetchStakingData(
  //   Token.JIT_BNB_LP_DEPRECATED
  // );
  // const fetchLegacyLPStakingData = useFetchStakingData(Token.JIT_BNB_LP_LEGACY);

  const fetchBalances = useCallback(async () => {
    console.log("address ", JITToken);

    try {
      setLoading(true);
      const [JIT, lp, lp1, lp2] = await Promise.all([
        //phb, deprecatedLp, legacyLp
        // address && phbToken ? phbToken.balanceOf(address) : constants.Zero,
        address && JITToken ? JITToken.balanceOf(address) : constants.Zero,
        address && lpToken ? lpToken.balanceOf(address) : constants.Zero,
        address && lpContractL2RBnb
          ? lpContractL2RBnb.balanceOf(address)
          : constants.Zero,
        address && lpContractL2RBusd
          ? lpContractL2RBusd.balanceOf(address)
          : constants.Zero,
        // fetchPHBStakingData(),
        fetchJITStakingData(),
        fetchLPStakingData(),
        fetchLPStakingData1(),
        fetchLPStakingData2(),
        // fetchDeprecatedLPStakingData(),
        // fetchLegacyLPStakingData(),
      ]);

      // setAvailablePHB(phb);
      setAvailableJIT(JIT);
      setAvailableLP(lp);
      setAvailableLP1(lp1);
      setAvailableLP2(lp2);
      // setAvailableDeprecatedLP(deprecatedLp);
      // setAvailableLegacyLP(legacyLp);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to loading balances", { variant: "error" });
    }
    setLoading(false);
  }, [
    setLoading,
    address,
    JITToken,
    lpToken,
    lpContractL2RBnb,
    lpContractL2RBusd,
    // deprecatedLpToken,
    // legacyLpToken,
    // fetchPHBStakingData,
    fetchJITStakingData,
    fetchLPStakingData,
    fetchLPStakingData1,
    fetchLPStakingData2,
    // fetchDeprecatedLPStakingData,
    // fetchLegacyLPStakingData,
    // setAvailablePHB,
    setAvailableJIT,
    setAvailableLP,
    setAvailableLP1,
    setAvailableLP2,
    // setAvailableDeprecatedLP,
    enqueueSnackbar,
  ]);

  return fetchBalances;
}
