/** @format */

import { useWeb3Modal } from "@web3modal/react";

const isAvailable = true;

export default function ConnectButton({ rounded, ...props }) {
  const { open } = useWeb3Modal();
  return (
    <button
      onClick={() => open()}
      disabled={!isAvailable}
      className='typography-brand-body-l-caps group min-w-[256px] m-auto animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start'>
      <div className='transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300'>
        <div className='disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full btn-gra group-focus-visible:bg-white hover:bg-white text-white'>
          {isAvailable ? "Connect Wallet" : "Live Soon"}
        </div>
      </div>
    </button>
  );
}
