/** @format */

import { Button, ButtonProps, CircularProgress } from "@material-ui/core";




export default function PrimaryButton({
  loading = false,
  disabled,
  children,
  ...props
}) {
  return (
    <button disabled={loading || disabled} {...props}>
      {loading ? (
        <CircularProgress color='inherit' size={24} thickness={2} />
      ) : (
        children
      )}
    </button>
  );
}
