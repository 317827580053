/** @format */

import { useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";
import { providers } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { ChainName } from "@utils/constants";
import { formatAddress } from "@utils/formatters";
import { useAccount, useEnsName, useNetwork } from "wagmi";
import Web3 from "web3";

const web3 = new Web3(
  Web3.givenProvider ||
    "https://mainnet.infura.io/v3/d5c09c17fb5446728d3818bc4afeca69",
);

export default function useWallet() {
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { open, close } = useWeb3Modal();
  const { chain } = useNetwork();
  const { enqueueSnackbar } = useSnackbar();

  const shortAccount = useMemo(
    () => (address ? formatAddress(address) : ""),
    [address],
  );
 

  const provider = useMemo(
    () =>
      web3 && chain?.id
        ? new providers.Web3Provider(web3.givenProvider, {
            name: ChainName,
            chainId: chain?.id,
          })
        : null,
    [web3, chain?.id],
  );



  // useEffect(() => {
  //   if (provider) {
  //     provider.on("block", (blockNumber) => {
  //       console.log("blockNumber", blockNumber);
  //     });
  //     return () => {
  //       provider.removeAllListeners();
  //     };
  //   }
  // }, [provider]);

  return {
    shortAccount,
    provider,
    ChainName,
  };
}
