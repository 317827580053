/** @format */

import { useCallback, useMemo, useRef } from "react";
import { Box, Button, InputBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import { BigNumber } from "ethers";
import prettyMilliseconds from "pretty-ms";
import PrimaryButton from "@components/PrimaryButton";
import { TokenName } from "@utils/constants";
import { getFullDisplayBalance } from "@utils/formatters";
import { useAtomValue } from "jotai/utils";
import { tokenStatAtomFamily } from "@/atoms/stat";

const useStyles = makeStyles(({ palette }) => ({
  root: {},
  inputBox: {
    display: "flex",
    alignItems: "center",
    borderRadius: 10,
    border: `1px solid #141414`,
    overflow: "hidden",
  },
  token: {
    padding: 12,
    display: " inline-flex",
    alignItems: "center",
  },
  logo: {
    height: 32,
  },
  input: {
    flex: 1,
    padding: "10px 12px",
    borderLeft: `1px solid #141414`,
    fontSize: 24,
    color: "rgb(107, 114, 128)",
  },

  maxLabelBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
    color: "#e730a8",
  },

  maxLabel: {
    fontSize: 10,
    fontWeight: 700,
    color: "grey",
  },
}));

export default function AmountInput({
  token,
  input,
  onInput,
  amount,
  max,
  lockDownSeconds,
  btnLabel,
  logo,
  loading = false,
  disabled = false,
  onSubmit,
}) {
  const classes = useStyles();

  const maxRef = useRef();
  const { rewardsDurationSeconds } = useAtomValue(tokenStatAtomFamily(token));
  const setMax = useCallback(() => {
    if (!amount.eq(max)) {
      maxRef.current = true;
      onInput(
        getFullDisplayBalance(max, { mantissa: 6, trimMantissa: true }),
        true,
      );
    }
  }, [amount, max, onInput]);

  const lockDownTime = useMemo(
    () =>
      lockDownSeconds
        ? prettyMilliseconds(lockDownSeconds.toNumber() * 1000, {
            verbose: true,
          })
        : null,
    [lockDownSeconds],
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.inputBox}>
        <span className={classes.token}>
          {/* {logo ? <img src={logo} alt={""} className={classes.logo} /> : null}
          <Typography>{TokenName[token]}</Typography> */}
          <img src='assets/logo2.png' alt={""} className={classes.logo} />
        </span>
        <NumberFormat
          value={input}
          onValueChange={values => {
            onInput(values.value, maxRef.current);
            maxRef.current = false;
          }}
          allowNegative={false}
          thousandSeparator
          isNumericString
          placeholder='0.0'
          customInput={InputBase}
          className={`${classes.input} font-head `}
        />
        <button
          className=' select-none disabled:cursor-not-allowed rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
          onClick={setMax}>
          Max
        </button>
      </Box>
      <Box className={classes.maxLabelBox}>
        <Typography variant='overline' style={{ color: "#455a64" }}>
          {/* {rewardsDurationSeconds && `Lock: ${rewardsDurationSeconds}`} */}
        </Typography>
        <Typography
          variant='overline'
          color={amount.gt(max) ? "error" : "primary"}
          className={classes.maxLabel}>
          {getFullDisplayBalance(max)} Available
        </Typography>
      </Box>
      <PrimaryButton
        size='large'
        className='flex max-w-4xl btn w-full font-para text-lg  border-0 fw-bold items-center  font-para py-2 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
        fullWidth
        disabled={disabled || amount.gt(max)}
        onClick={onSubmit}
        loading={loading}>
        {btnLabel}
      </PrimaryButton>
    </Box>
  );
}
