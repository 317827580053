/** @format */

import { Box, Backdrop, Typography, BackdropProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    fontSize: 22,
    textAlign: "center",
    backgroundColor: "#a3a3aef1",
    width: "100%",
    margin: 0,
    borderRadius: 20,
  },
})(Backdrop);

export default function Pending({ children, ...props }) {
  return (
    <StyledBackdrop open {...props}>
      {/* <Box>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='30'
          height='30'
          fill='currentColor'
          className='bi bi-lock-fill m-auto'
          viewBox='0 0 16 16'>
          <path d='M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2' />
        </svg>
        <Typography variant='h6' display='block'>
          This pool will go live after <br />
          Spot Trading launch which is
          <br />
          <small>on Jan, 6th 2024 at 16:15 UTC</small>
        </Typography>
      </Box> */}
    </StyledBackdrop>
  );
}
