/** @format */

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.scss";
import Home from "./Home";
import Header from "./layouts/Header1";
import Footer from "./layouts/Footer";
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-193186453-1');
// ReactGA.pageview(window.location.pathname + window.location.search);
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { bsc, bscTestnet } from "wagmi/chains";

function App() {
  const chains = [bsc];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);
  return (
    <div className='relative flex min-h-screen w-screen px-[11px] py-[12px] sm:py-[11px] h-auto sm:h-screen'>
      <div className='relative flex min-h-full w-full flex-1 flex-col rounded-md gradient-border transition-colors bg-gradient-to-b from-[#3017488e] from-[27.54%] to-[#e2107c96]  '>
        <div className='relative z-10 flex flex-1 flex-col pb-16 h-auto sm:h-full bg-img'>
          <div className='flex flex-1 sm:overflow-hidden'>
            <div className='flex flex-1 flex-col'>
              <div className='sticky top-[14px] z-10 sm:top-[13px]'>
                <div className='relative'>
                  <Header />
                  <div className='absolute bottom-0 left-0 right-0 transition-opacity duration-300 opacity-0'>
                    <div className='w-full h-[2px] bg-camo-500' />
                  </div>
                  <div className='absolute inset-0 top-[-12px] -z-10 transition-opacity duration-300 opacity-0 backdrop-blur-[12px]' />
                </div>
              </div>
              <main className='relative flex h-full flex-1 px-6 pt-6  lg:pt-0 overflow-hidden sm:pr-0'>
                <div className='flex flex-1 flex-col mt-8 lg:mt-0 animate-enter-fade gap-12'>
                  <div className='flex flex-col gap-8 text-center'>
                    <h1 className='typography-brand-heading-1 max-w-[16ch] m-auto animate-appear-slide-up text-white relative  '>
                      The L2 that helps you earn more
                      <span
                        className='mt-2 inline-block overflow-hidden rounded-bl-lg rounded-tr-lg bg-[#33144A] align-middle transition-[width] duration-1000 xs:ml-4 xs:mt-0 xs:inline-block'
                        style={{ height: 53, width: 125 }}>
                        <span
                          className='flex flex-col items-start text-[#E2107B] transition-transform duration-1000 '
                          style={{ transform: "translateY(calc(0px))" }}>
                          <span className='px-2 leading-[125%] xs:px-3'>
                            BNB
                          </span>
                          <span className='px-2 leading-[125%] xs:px-3'>
                            USD
                          </span>
                          <span className='px-2 leading-[125%] xs:px-3'>
                            Yield
                          </span>
                        </span>
                      </span>
                    </h1>
                    <WagmiConfig config={wagmiConfig}>
                      <Home />
                      <div className='z-[9999]    relative'>
                        <Web3Modal
                          projectId={projectId}
                          ethereumClient={ethereumClient}
                          themeMode='light'
                          themeVariables={{
                            "--w3m-background": "#e2107c",
                            "--w3m-font-family": "Geom Graphic W03 Regular",
                            "--w3m-background-color": "#e2107c",
                            "--w3m-text-primary": "#000",
                            "--w3m-color-bg-1": "#e2107c",
                            "--w3m-color-bg-2": "#e2107c",
                            "--w3m-color-bg-3": "#e2107c",
                            "--w3m-color-fg-2": "#fff",
                            "--w3m-color-fg-3": "#fff",
                            "--w3m-color-fg-1": "#fff",
                            "--w3m-accent-color": "#fff",
                          }}
                          defaultChain={97}
                        />
                      </div>
                    </WagmiConfig>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
        <div className='bg-black duration-[0ms] opacity-0 pointer-events-none absolute inset-0 z-0 h-full w-full transition-opacity' />
      </div>
      <Footer />
    </div>
  );
}

export default App;
