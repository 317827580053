/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider as JotaiProvider } from "jotai";
import { SnackbarProvider } from "notistack";

import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { bsc, bscTestnet } from "wagmi/chains";
import "./index.css";
import Home1 from "./layouts/Home";

const root = ReactDOM.createRoot(document.getElementById("root"));
const chains = [bsc];
const projectId = "1c200fb2c2e182bbda9170cd52450702";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <JotaiProvider>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
          }}
          preventDuplicate>
          <App />
        </SnackbarProvider>
      </JotaiProvider>
      <div className='z-[9999]    relative'>
        <Web3Modal
          projectId={projectId}
          ethereumClient={ethereumClient}
          themeMode='light'
          themeVariables={{
            "--w3m-background": "#e2107c",
            "--w3m-font-family": "Geom Graphic W03 Regular",
            "--w3m-background-color": "#e2107c",
            "--w3m-text-primary": "#000",
            "--w3m-color-bg-1": "#e2107c",
            "--w3m-color-bg-2": "#e2107c",
            "--w3m-color-bg-3": "#e2107c",
            "--w3m-color-fg-2": "#fff",
            "--w3m-color-fg-3": "#fff",
            "--w3m-color-fg-1": "#fff",
            "--w3m-accent-color": "#fff",
          }}
          defaultChain={97}
        />
      </div>
    </WagmiConfig>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
