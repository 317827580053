/** @format */

import { useCallback, useState, useMemo } from "react";
import { useAtomValue } from "jotai/utils";
import { Box, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import CountUp from "react-countup";
import { cardContent } from "@utils/theme/common";
import useFetchState from "@hooks/useFetchState";
import PrimaryButton from "@components/PrimaryButton";
import { earnedAtomFamily } from "@atoms/balance";
import { getFullDisplayBalance } from "@utils/formatters";
import useStaking from "@hooks/useStaking";

const useStyles = makeStyles({
  root: {
    ...cardContent,
    display: "flex",
    alignItems: "center",
  },
  amount: {
    flex: 1,
    overflow: "hidden",
  },
});

export default function Earned({ token }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const refresh = useFetchState();
  const earned = useAtomValue(earnedAtomFamily(token));

  const stakingContract = useStaking(token);

  const end = useMemo(
    () => parseFloat(getFullDisplayBalance(earned).replace(/,/g, "")),
    [earned],
  );
  // console.log("end ==> ", end);
  const handleHarvest = useCallback(async () => {
    if (stakingContract) {
      try {
        setLoading(true);
        const tx = await stakingContract.getReward();
        enqueueSnackbar(
          <>
            Transaction has been sent to blockchain,
            <br />
            waiting for confirmation...
          </>,
          { variant: "info" },
        );
        const res = await tx.wait(1);
        console.log("Harvest:", res);
        enqueueSnackbar(
          `Successfully harvested ${getFullDisplayBalance(earned)} L2R`,
          { variant: "success" },
        );
        refresh();
      } catch (e) {
        console.log(e);
        // enqueueSnackbar(e.error ?? "Operation Failed", { variant: "error" });
      }
      setLoading(false);
    }
  }, [earned, enqueueSnackbar, refresh, stakingContract]);

  return (
    <Box className={classes.root}>
      <Box className={classes.amount}>
        <h3 className='typography-brand-heading-3  text-gray-300'>
          L2R EARNED
        </h3>

        <CountUp
          start={0}
          end={end}
          delay={0.1}
          duration={end === 0 ? 0 : 2}
          decimals={3}
          preserveValue>
          {({ countUpRef }) => (
            <h1
              ref={countUpRef}
              className='typography-brand-heading-3  text-yellow-300 '
              variant='body1'></h1>
          )}
        </CountUp>
      </Box>
      {/* <PrimaryButton
        className='flex max-w-4xl btn font-para text-lg  border-0 fw-bold items-center  font-para py-2 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
        loading={loading}
        size='large'
        disabled={earned.lte(0)}
        onClick={handleHarvest}>
        Harvest
      </PrimaryButton> */}
    </Box>
  );
}
