/** @format */

import React from "react";
// import { Link, useLocation } from "react-router-dom";

const Header = () => {
  // const { pathname } = useLocation();

  return (
    <header className='relative z-[11]  h-max  px-6 pb-3 pt-9 sm:ml-20 sm:pl-5 sm:pr-0 sm:pt-10 lg:pt-4 sm:mr-0'>
      <nav className='navbar navbar-expand-md '>
        <div className='container'>
          <a
            className='flex items-center sm:hidden'
            style={{ width: 126 }}
            href='/'>
            <img
              alt='L2reum Logo'
              loading='lazy'
              width={126}
              // height={32}
              style={{ color: "transparent" }}
              src='images/logo.svg'
            />
          </a>
          <a
            className='hidden items-center sm:flex lg:hidden'
            style={{ width: 170 }}
            href='/'>
            <img
              alt='L2reum Logo'
              loading='lazy'
              className=' aspect-auto'
              width={170}
              decoding='async'
              style={{ color: "transparent" }}
              srcSet='images/logo.svg'
              src='images/logo.svg'
            />
          </a>
          <a
            className='hidden items-center lg:flex'
            style={{ width: 226 }}
            href='/'>
            <img
              alt='L2reum Logo'
              loading='lazy'
              width={226}
              // height={54}
              decoding='async'
              style={{ color: "transparent" }}
              srcSet='images/logo.svg'
              src='images/logo.svg'
            />
          </a>

          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarText'
            aria-controls='navbarText'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarText'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'></ul>
            <span className='mr-7'>
              <ul className='navbar-nav me-auto mb-2 mb-lg-0  '>
                <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4 '>
                  <a
                    className={`interactive-text text-gray-400  ${
                      "pathname" === "/" ? "text-pink-600 font-bold" : ""
                    }`}
                    href='/'>
                    Home
                  </a>
                </div>
                <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4 '>
                  <a
                    className={`interactive-text text-gray-400  ${
                      "pathname" === "/airdrop" ? "text-pink-600 font-bold" : ""
                    }`}
                    href='/airdrop'>
                    Airdrop
                  </a>
                </div>
                <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4'>
                  <a
                    className={`interactive-text text-gray-400  ${
                      "pathname" === "/leaderboard"
                        ? "text-pink-600 font-bold"
                        : ""
                    }`}
                    href='/'>
                    Leaderboard
                  </a>
                </div>
                <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4'>
                  <a
                    className={`interactive-text text-gray-400  ${
                      "pathname" === "/devs" ? "text-pink-600 font-bold" : ""
                    }`}
                    href='/devs'>
                    Devs
                  </a>
                </div>
                <div className='typography-brand-body-l-caps px-2 py-2 sm:px-4'>
                  <a
                    className={`interactive-text text-gray-400  ${
                      "pathname" === "/about" ? "text-pink-600 font-bold" : ""
                    }`}
                    href='/about'>
                    About
                  </a>
                </div>
              </ul>
            </span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
