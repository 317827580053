/** @format */

import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRequest from "@ahooksjs/use-request";
import StakeCard from "@components/StakeCard";
import { Token } from "@utils/constants";
import useFetchStats from "@hooks/useFetchStats";
import useFetchState from "@hooks/useFetchState";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
const useStyles = makeStyles({
  container: {
    padding: "6vh 24px 24px",
    display: "flex",
    flexWrap: "wrap", 
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
    overflow: "hidden",
  },
  card: {
    margin: "20px !important",
  },
});

const cards = [
  {
    token: Token.JIT,
    bg: "./assets/bgs/L2R.png",
    color: "#fff",
    cardTitle: "Low Performer",
    desc: <>Adjustable</>,
    para: "Stake L2R and earn  300% flexible reward",
    open: true,
    pendingText: (
      <>
        The Pool will be live once <br />
        <strong>other two staking pools filled</strong>
        <br />
        <small>No max wallet limit - 1,500,000 reward in L2R</small>
      </>
    ),
  },
  {
    token: Token.JIT_BNB_LP,
    bg: "./assets/bgs/L2R.png",
    color: "#fff",
    cardTitle: "Mid Performer ",
    desc: <>7 Days</>,
    para: "Stake L2R tokens and earn fix 450% reward",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>16:15 UTC after PCS listing!</strong>
        <br />
        <small>1000 max wallet - 1,000,000 reward in L2R</small>
      </>
    ),
  },
  {
    token: Token.JIT_L2R_LP,
    bg: "./assets/bgs/L2R.png",
    color: "#fff",
    cardTitle: "High Performer",
    desc: <>30 Days</>,
    para: "Stake L2R tokens and earn fix 700% reward",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>18:30 UTC!</strong>
        <br />
        <small>2000 max wallet - 2,000,000 reward in L2R</small>
      </>
    ),
  },
];

export default function Home() {
  const classes = useStyles();
  const { isConnected } = useAccount();

  const fetchStats = useFetchStats();
  const fetchState = useFetchState();

  useRequest(fetchStats, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });

  const { run, cancel } = useRequest(fetchState, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });
  const { chain } = useNetwork();
  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();
  useEffect(() => {
    if (isConnected && chain?.id === 56) {
      run();
    } else {
      // switchNetwork?.("56");
      cancel();
    }
  }, [cancel, isConnected, run]);

  return (
    <div className={classes.container}>
      {cards.map(card => (
        <StakeCard key={card.token} {...card} className={classes.card} />
      ))}
    </div>
  );
}
